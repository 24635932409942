import React from "react";

const Footer = () => {
  return (
    <div className="p-8 pt-[20rem] pb-8 text-stone-700">
      ty for stopping by. means a lot. <br />
      <br />- love john.
    </div>
  );
};

export default Footer;
