import React from "react";
import Promises from "../Components/Promises";
import Typewriter from "typewriter-effect";

const Home = () => {
  return (
    <div className="flex flex-col w-full justify-center min-h-[46rem] p-8 gap-[28rem]">
      <Header />
      <Story />
      <Promises />
      <Conclusion />
    </div>
  );
};

export default Home;

const Header = () => {
  return (
    <div className=" justify-center flex flex-col gap-4 w-full pt-[20rem]">
      <div className="text-6xl lg:text-8xl font-bold">
        <Typewriter
          onInit={(typewriter) => {
            typewriter
              //type random word
              .typeString("hi. this is john.")
              .callFunction(() => {
                console.log("String typed out!");
              })
              .pauseFor(3500)

              .start();
          }}
        />
      </div>
      <div className=" text-stone-600 md:w-1/4 w-4/5">
        i'm running for it rep. i want to share my passion for tech with y'all.
        whether you’re in humanities, arts, or stem - tech can help you do more{" "}
        <br />
        <br />i will show you how.
      </div>
    </div>
  );
};
const Conclusion = () => {
  return (
    <div className=" justify-center flex flex-col gap-4 w-full">
      <div className="text-4xl lg:text-6xl font-bold">
        <Typewriter
          onInit={(typewriter) => {
            typewriter
              //type random word
              .typeString("the bottom line.")
              .callFunction(() => {
                console.log("String typed out!");
              })
              .pauseFor(3500)

              .start();
          }}
        />
      </div>
      <div className=" text-stone-600 md:w-1/4 w-4/5">
        i’m here to make tech actually work for you. whether it’s fixing what’s
        broken or showing you how to make your life easier, i’ve got you.
      </div>
    </div>
  );
};

const Story = () => {
  return (
    <div className=" justify-center flex flex-col gap-4 w-full">
      <div className="text-4xl font-bold">first. let me tell you my story.</div>
      <div className=" text-stone-600 md:w-1/4 w-4/5">
        growing up, i felt like i had big shoes to fill. my brothers? geniuses.
        one’s a software engineer at dell. the other president of the computer
        society of zimbabwe. crazy. i felt so much pressure to keep up. but tbh,
        i hated coding. like, reaally hated it. it didn’t click. until i
        realized, there's more to tech than just coding. and you can use it in
        any field
      </div>
    </div>
  );
};
