import { Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";

function App() {
  return (
    <div className="min-h-screen overflow-x-hidden lg:pl-20 flex flex-col justify-between bg-black text-white font-manrope">
      <Navbar />
      <div className="flex-grow h-full w-full overflow-x-hidden ">
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
      <Footer className="" />
    </div>
  );
}

export default App;
