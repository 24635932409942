import React from "react";

const Navbar = () => {
  const today = new Date().toLocaleDateString("en-US", {
    weekday: "long",
    day: "numeric",
    month: "long",
  });
  return <div className="p-8">{today}</div>;
};

export default Navbar;
