import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

const promises = [
  {
    for: "humanities students",
    what: 'i’ll show you how to use <a target="_blank" class="text-stone-600 font-bold" class="text-blue-100 font-bold" href="https://www.perplexity.ai/">perplexity.ai</a> to get quick summaries and citations, making your research faster and easier.',
  },
  {
    for: "trip & event organizers",
    what: "i’ll help you automate google forms so that it automatically sends calendar invites and stop collecting responses when max sign ups are reached",
  },
  {
    for: "stem students",
    what: 'i’ll introduce you to <a target="_blank" class="text-stone-600 font-bold" class="text-blue-100 font-bold" href="https://www.google.com/labs/notebooklm">notebooklm</a>, google’s note-taking app that analyzes documents and creates summaries, helping you understand complex topics better.',
  },
  {
    for: "RAs",
    what: 'i’ll help you set-up interactive online games like <a target="_blank" class="text-stone-600 font-bold" class="text-blue-100 font-bold" href="https://drawbattle.io/">https://drawbattle.io/ </a> for hall events, so you can engage with residents and make hall activities fun',
  },
  {
    for: "students who struggle to focus in class",
    what: 'i’ll connect you with <a target="_blank" class="text-stone-600 font-bold" class="text-blue-100 font-bold" href="https://unstuckstudy.com/">Unstuck AI</a>, a tool that allows you to record lectures and chat with your notes (this is my personal favorite).',
  },
  {
    for: "artists",
    what: 'i’ll help you monetize your art by introducing you to platforms like <a target="_blank" class="text-blue-500 underline hover:text-blue-700" href="https://www.redbubble.com/">redbubble</a> and <a target="_blank" class="text-blue-500 underline hover:text-blue-700" href="https://www.etsy.com/">etsy</a>, where you can sell your creations',
  },

  {
    for: "aspiring musicians",
    what: 'i’ll introduce you to <a target="_blank" class="text-stone-600 font-bold" class="text-blue-100 font-bold" href="https://www.aiva.ai/">aiva</a>, a music composition tool that helps you create original tracks, turning your ideas into reality.',
  },
  {
    for: "content creators",
    what: 'perhaps get an SG funded Canva Pro account? i’ll help you understand social media algorithms and use <a target="_blank" class="text-stone-600 font-bold" class="text-blue-100 font-bold" href="https://clickup.com/ai">clickup ai</a> to come up with content ideas, write scripts, and optimize posts, so you can grow your audience.',
  },
  {
    for: "everyone",
    what: "i’ll teach you prompt engineering techniques to get the most out of these tools, making your interactions with technology more effective and personalized.",
  },
];

export default function Promises() {
  return (
    <div className="bg-black">
      <div className="max-w-4xl  py-24 sm:py-32 lg:py-40">
        <div className="divide-y divide-white/10">
          <h2 className="text-4xl font-semibold tracking-tight text-white sm:text-2xl">
            here’s what i will deliver to  you.
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-white/10">
            {promises.map((promise) => (
              <Disclosure key={promise.for} as="div" className="pt-6">
                <dt>
                  <DisclosureButton className="group flex w-full items-start justify-between text-left text-stone-500">
                    <span className="text-base/7 font-semibold">
                      {promise.for}
                    </span>
                    <span className="ml-6 flex h-7 items-center">
                      <PlusSmallIcon
                        aria-hidden="true"
                        className="size-6 group-data-[open]:hidden"
                      />
                      <MinusSmallIcon
                        aria-hidden="true"
                        className="size-6 [.group:not([data-open])_&]:hidden"
                      />
                    </span>
                  </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd" className="mt-2 pr-12">
                  <p
                    className="text-base/7 text-gray-300"
                    dangerouslySetInnerHTML={{ __html: promise.what }}
                  ></p>
                </DisclosurePanel>
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
